import React, { useState } from "react";

import Nav from "../../components/Nav";
import styles from "./index.module.scss";
import banner1 from "../../assets/banner1.svg";
import spinner from "../../assets/spinner.svg";
import { Link } from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import { loginUser } from "../../store/actions/auth";
import { useMappedState } from "redux-react-hook";

const Signin = ({ history }) => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});
  const user = useMappedState(state => state.auth);

  const onChange = e => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value
    });
  };
  const submit = e => {
    e.preventDefault();
    dispatch(loginUser(details, history));
  };
  return (
    <div className={styles.signin}>
      <Nav onlyHome />
      <div className={styles.main}>
        <div
          className={styles.left}
          style={{
            background: `url(${banner1}) center center`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
          }}
        >
          <div className={styles.text}>
            Affordable houses at your finger tips
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.formGroup}>
            <div className={styles.head1}>Welcome back!</div>
            <div className={styles.head2}>
              Want to join the Firsthomes team?{" "}
              <Link to="/signup">Sign up</Link>
            </div>

            <form onSubmit={submit}>
              <input
                type="email"
                name="email"
                onChange={onChange}
                placeholder="Email"
              />
              <input
                type="password"
                name="password"
                onChange={onChange}
                placeholder="Password"
              />
              <button
                type="submit"
                className={styles.submit}
                value={spinner}
                disabled={user.loading}
              >
                {user.loading ? (
                  <img src={spinner} alt="" />
                ) : (
                  <span>SIGN IN</span>
                )}
              </button>
              <Link to="/forgot-password" className={styles.forgot}>
                FORGOT PASSWORD?
              </Link>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
