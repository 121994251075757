import React, { useState, useEffect } from "react";
import spinner from "../../../assets/spinnerblue.svg";
import purse from "../../../assets/save.svg";
import styles from "./index.module.scss";
import "react-circular-progressbar/dist/styles.css";
import { useMappedState, useDispatch } from "redux-react-hook";
import { fetchCalculation } from "../../../store/actions/onboard";
import { allCards, volutaryPay } from "../../../store/actions/card";
import Table from "../../../components/Table";
import {
  getDeposits,
  getWithdrawal,
  getContratual
} from "../../../store/actions/wallet";
import SelectInput from "../../../components/SelectInput";
const SaveNow = () => {
  const dispatch = useDispatch();
  const user = useMappedState(({ auth }) => auth.currentUser);
  const deposits = useMappedState(({ wallet }) => wallet.deposits);
  const { cards, loading } = useMappedState(({ card }) => card);

  const [amount, setAmount] = useState(null);
  const [method, setMethod] = useState(null);
  const [payment, setPayment] = useState({});
  useEffect(() => {
    dispatch(fetchCalculation());
    dispatch(allCards());
    dispatch(getDeposits());
    dispatch(getWithdrawal());
    dispatch(getContratual());
  }, [user, dispatch]);

  const cardCollection = cards.map(card => ({
    value: card._id,
    label: `${card.type} ${card.last4Digits}`
  }));

  const handleSelect = data => {
    setPayment({
      ...payment,
      cardID: data.value
    });
    return setMethod(data);
  };
  const payNow = e => {
    e && e.preventDefault();
    return dispatch(
      volutaryPay(payment, () => {
        setPayment({});
        setMethod(null);
        setAmount("");
      })
    );
  };

  return (
    <div className={styles.save}>
      <div className={styles.head}>
        <div className={styles.title}>Save Now</div>
      </div>
      <div className={styles.group}>
        <div className={styles.card}>
          <div className={styles.top}>
            <img src={purse} alt="" /> Save now
          </div>
          <div className={styles.sub}>
            Make an instant equity or voluntary contribution.
          </div>
          <form onSubmit={payNow}>
            <label htmlFor="">How much do you want to contribute?</label>
            <input
              type="number"
              name="amount"
              onChange={e => {
                e.preventDefault();
                setAmount(e.target.value);
                return setPayment({
                  ...payment,
                  [e.target.name]: Number(e.target.value)
                });
              }}
              value={amount || ""}
              placeholder="&#8358; 60,000"
            />
            <SelectInput
              small
              noborder
              blue
              placeholder="Select a Payment Method"
              name="paymentMethod"
              value={method}
              changer={handleSelect}
              data={cardCollection}
            />
            {loading ? (
              <img
                style={{
                  height: "45px"
                }}
                src={spinner}
                alt="loader"
              />
            ) : (
              <input type="submit" value="Save now" />
            )}
          </form>
        </div>
        <div className={styles.table}>
          <Table
            title="Contribution History"
            showTitle
            header={["Amount", "date", "reference", "point"]}
            data={deposits}
            conditions={{
              double: "status",
              badge: "successful"
            }}
            dataType={[
              { depositAmount: "double" },
              { depositDate: "text" },
              { reference: "truncate" },
              { pointsGained: "boldText" }
            ]}
            showFoot
          />
        </div>
      </div>
    </div>
  );
};
export default SaveNow;
