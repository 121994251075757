import React from "react";

import Nav from "../../components/Nav";
import styles from "./index.module.scss";
import lady from "../../assets/girl.jpg";
import { Link } from "react-router-dom";

const SignupInfluencer = props => {
  return (
    <div className={styles.signup}>
      <Nav onlyHome />
      <div className={styles.main}>
        <div
          className={styles.left}
          style={{
            background: `url(${lady}) center `,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
          }}
        >
          <div>
            <div className={styles.text}>Welcome to Firsthomes!</div>
            <div className={styles.text2}>
              Get your campaign live and start inviting targeted users in
              minutes.{" "}
            </div>
            <div className={styles.text3}>
              By accessing and utilizing this platform you agree to be bound by
              the Firsthomes <Link to="#">Privacy Policy</Link> and{" "}
              <Link to="#">Terms of Use.</Link>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.formGroup}>
            <div className={styles.head}>
              Already have an account? <Link to="/signin">Sign in</Link>
            </div>
            <form>
              <input type="text" name="firstname" placeholder="First name" />
              <input type="text" name="lastname" placeholder="Last name" />
              <input type="text" name="phone" placeholder="Phone number" />
              <input type="email" name="email" placeholder="Email address" />
              <input type="password" name="password" placeholder="Password" />
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm password"
              />
              <input type="submit" value="SIGN UP" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupInfluencer;
