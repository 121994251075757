import React from "react";

import Nav from "../../components/Nav";
import styles from "./index.module.scss";
import lady from "../../assets/girl.jpg";
import { Link } from "react-router-dom";

const SignupTwo = props => {
  return (
    <div className={styles.signup}>
      <Nav onlyHome />
      <div className={styles.main}>
        <div
          className={styles.left}
          style={{
            background: `url(${lady}) center`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
          }}
        >
          <div>
            <div className={styles.text}>Welcome to Firsthomes!</div>
            <div className={styles.text2}>
              Use your Instagram or Twitter account to easily get started, or
              fill out the form.
            </div>
            <div className={styles.text3}>
              By accessing and utilizing this platform you agree to be bound by
              the Firsthomes <Link to="#">Privacy Policy</Link> and{" "}
              <Link to="#">Terms of Use.</Link>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.formGroup}>
            <div className={styles.head}>
              Already have an account? <Link to="/signin">Sign in</Link>
            </div>
            <div className={styles.headDash}>
              <hr />
              <p>Sign up with</p>{" "}
            </div>
            <div className={styles.social}>
              <button className={styles.instaBtn}>
                {" "}
                <i className="lni-instagram" /> Instagram
              </button>
              <button className={styles.twitBtn}>
                <i className="lni-twitter-filled" /> Twitter
              </button>
            </div>
            <div className={styles.headDash}>
              <hr />
              <p>Or </p>
            </div>

            <form>
              <input type="text" name="fullname" placeholder="Full name" />
              <input type="text" name="phone" placeholder="Phone number" />
              <input type="email" name="email" placeholder="Email address" />
              <input type="password" name="password" placeholder="Password" />
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm password"
              />
              <input type="submit" value="SIGN UP" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupTwo;
