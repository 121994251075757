import React from "react";
import styles from "./index.module.scss";

const FullBanner = ({ title, image, Bottom }) => {
  return (
    <div
      className={styles.fullBanner}
      style={{
        background: `url(${image}) center`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
      }}
    >
      <div className={styles.center}>
        <div className={styles.title}>{title}</div>
        <div className={styles.bottom}>
          <Bottom />
        </div>
      </div>
    </div>
  );
};

export default FullBanner;
