import Axios from "axios";
import {
  ADD_CARD_SUCCESS,
  ADD_CARD_LOADING,
  ADD_CARD_FAILURE,
  ALL_CARD_SUCCESS,
  ALL_CARD_LOADING,
  ALL_CARD_FAILURE,
  ADD_CARD_OTP,
  ADD_CARD_PIN,
  ADD_CARD_URL,
  ADD_CARD_ADDRESS
} from "./types/card";

import { setNotify } from "./notify";
import { getDeposits, getContribution } from "./wallet";

const addCardSuccess = () => ({
  type: ADD_CARD_SUCCESS
});
const addCardLoading = status => ({
  type: ADD_CARD_LOADING,
  status
});
const addCardFailure = () => ({
  type: ADD_CARD_FAILURE
});

const allCardSuccess = cards => ({
  type: ALL_CARD_SUCCESS,
  cards
});
const allCardLoading = () => ({
  type: ALL_CARD_LOADING
});
const allCardFailure = () => ({
  type: ALL_CARD_FAILURE
});

const cardNeedOtp = transaction_reference => ({
  type: ADD_CARD_OTP,
  transaction_reference
});

const cardNeedPin = suggested_auth => ({
  type: ADD_CARD_PIN,
  suggested_auth
});
const cardNeedUrl = data => ({
  type: ADD_CARD_URL,
  data
});

const cardNeedAddress = suggested_auth => ({
  type: ADD_CARD_ADDRESS,
  suggested_auth
});

export const addCard = (details, action, clearInputs, first) => dispatch => {
  dispatch(addCardLoading(true));

  return Axios.post("/card/add", {
    ...details
  })
    .then(async ({ data }) => {
      console.log("success", data);
      switch (data.status) {
        case 201:
          dispatch(cardNeedPin(data.suggested_auth));
          action && action["card"]();
          return dispatch(
            setNotify({
              type: "success",
              title: "Pin is needed",
              body: data.field.pin
            })
          );

        case 202:
          dispatch(cardNeedAddress(data.suggested_auth));
          action && action["card"]();
          return dispatch(
            setNotify({
              type: "success",
              title: "Billing details are needed",
              body: data.message
            })
          );
        case 203:
          dispatch(cardNeedUrl(data));
          action && action["card"]();
          return dispatch(
            setNotify({
              type: "success",
              title: "OTP is needed",
              body: data.message
            })
          );
        case 204:
          dispatch(cardNeedOtp(data.transaction_reference));
          action && action["card"]();
          return dispatch(
            setNotify({
              type: "success",
              title: "OTP is needed",
              body: "Enter OTP sent to you"
            })
          );

        default:
          dispatch(allCards());
          dispatch(addCardSuccess(data));
          clearInputs && clearInputs();
          action && action[first ? "success" : "start"]();
          return dispatch(
            setNotify({
              type: "success",
              title: "Card was successfully added",
              body: data.message
            })
          );
      }
    })
    .catch(error => {
      console.log(error);
      if (error.response) {
        dispatch(
          setNotify({
            type: "error",
            title: "Card error",
            body: error.response.data.message
          })
        );
      }

      return dispatch(addCardFailure());
    });
};

export const redirectCard = (details, action) => dispatch => {
  dispatch(addCardLoading(true));

  return Axios({
    method: "GET",
    url: "/card/redirect",
    headers: { ...details }
  })
    .then(async ({ data }) => {
      dispatch(allCards());
      dispatch(addCardSuccess());
      action && action["start"]();
      return dispatch(
        setNotify({
          type: "success",
          title: "Card was added successfully",
          body: data.message
        })
      );
    })
    .catch(error => {
      return dispatch(addCardFailure());
    });
};

export const validateCard = (details, clearInputs, action) => dispatch => {
  dispatch(addCardLoading(true));

  return Axios.post("/card/otp/validate", {
    ...details
  })
    .then(async ({ data }) => {
      dispatch(allCards());
      dispatch(addCardSuccess(data));
      clearInputs && clearInputs();

      action && action();
      return dispatch(
        setNotify({
          type: "success",
          title: "Card was successfully verified",
          body: data.message
        })
      );
    })
    .catch(error => {
      return dispatch(addCardFailure());
    });
};

export const allCards = () => dispatch => {
  dispatch(allCardLoading());

  return Axios.get("/card/all")
    .then(async ({ data: { data } }) => {
      return dispatch(allCardSuccess(data));
    })
    .catch(error => {
      return dispatch(allCardFailure());
    });
};

export const deleteCard = id => dispatch => {
  dispatch(addCardLoading(true));

  return Axios.delete(`card/delete/${id}`)
    .then(async ({ data: { data } }) => {
      dispatch(allCards());
      dispatch(addCardLoading(false));

      return dispatch(
        setNotify({
          type: "success",
          title: "Card was successfully deleted",
          body: data
        })
      );
    })
    .catch(error => {
      return dispatch(
        setNotify({
          type: "error",
          title: "Error while deleting card",
          body: error.response.data.message
        })
      );
    });
};

export const volutaryPay = (detail, action) => dispatch => {
  dispatch(addCardLoading(true));

  return Axios.post("card/voluntary", {
    ...detail
  })
    .then(async ({ data }) => {
      dispatch(addCardLoading(false));
      dispatch(getDeposits());
      dispatch(getContribution());
      action && action();
      return dispatch(
        setNotify({
          type: "success",
          title: "Payment Successfull",
          body: data.message
        })
      );
    })
    .catch(error => {
      console.log(error);
      if (error.response) {
        dispatch(
          setNotify({
            type: "error",
            title: "Error while making payment",
            body: error.response.data.message
          })
        );
      }

      return dispatch(addCardFailure());
    });
};
