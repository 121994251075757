import "./App.scss";

import { Route, BrowserRouter, Switch } from "react-router-dom";
import React from "react";
import About from "./pages/About";
import ArticleView from "./pages/ArticleView";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import OnBoarding from "./pages/OnBoarding";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import SignupInfluencer from "./pages/SignupInfluencer";
import SignupTwo from "./pages/SignUpTwo";
import Notify from "./components/Notify";
import ProtectedRoute from "./components/ProtectedRoute";
import Verify from "./pages/Verify";

const App = () => (
  <BrowserRouter>
    <Notify />
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/blog" component={Blog} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/blog/:id" component={ArticleView} />
      <Route exact path="/about" component={About} />
      <Route exact path="/signin" component={Signin} />
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/signup2" component={SignupTwo} />
      <Route exact path="/signup/influencer" component={SignupInfluencer} />
      <ProtectedRoute exact path="/onboarding" component={OnBoarding} />
      <ProtectedRoute path="/dashboard" component={Dashboard} />
      <ProtectedRoute path="/verify" component={Verify} />
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default App;
