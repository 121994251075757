import { Link } from 'react-router-dom';
import React from 'react';

import styles from './index.module.scss';



const Footer = (props) => {
	const Linker = ({ title, data }) => <div className={styles.linker}>
    <div className={styles.title}>{title}</div>
		<div className={styles.links}>
		{
			data && data.map(datum =>
				<Link key={datum.name} to={datum.path}>{datum.name}</Link>
			)
		}
	</div>
	</div>;

	const Social = ({ data }) => <div className={styles.social}>
    <div className={styles.title}>First homes on social media</div>
		<div className={styles.links}>
		{
			data && data.map(datum =>
				<a key={datum.name} href={datum.path} target="_blank" rel="noopener noreferrer"><i className={datum.name}></i></a>
			)
		}
	</div>
	<div className={styles.foot}>&copy; 2019 All rights reserved</div>
	</div>;

	return(
		<div className={styles.footer}>
			<div className={styles.suscribe}>
				<form className={styles.form}>
					<label>Subscribe to our newsletter</label>
				<div>
					<input className={styles.input} type="email" name="email" placeholder="Email address" />
				<input className={styles.btn} type="submit" name="" value="SUBSCRIBE" />
				</div>
				</form>
			</div>

			<div className={styles.nav}>
				<div className={styles.linkers}>
					<Linker
						title="Navigation"
						data={[
							{name: 'Homepage', path: '/'},
							{name: 'Sign up', path: '/signup'},
							{name: 'Sign in', path: '/signin'},
							{name: 'How it works', path: '/how-it-works'},
						]}
					/>

					<Linker
						title="Discover"
						data={[
							{name: 'About us', path: '/about'},
							{name: 'Contact us', path: '/contact'},
							{name: 'Blog', path: '/blog'},
						]}
					/>

					<Linker
						title="Get informed"
						data={[
							{name: 'FAQs', path: '/faqs'},
							{name: 'Security', path: '/security'},
						]}
					/>

					<Linker
						title="Get informed"
						data={[
							{name: 'Privacy policy', path: '/policy'},
							{name: 'Terms of use', path: '/terms'},
						]}
					/>
				</div>
				<Social
					data={[
						{name: 'lni-linkedin', path: 'https://linkedin.com'},
						{name: 'lni-facebook-filled', path: 'https://facebook.com'},
						{name: 'lni-twitter-filled', path: 'https://twitter.com'},
					{name: 'lni-instagram', path: 'https://instagram.com'},
				]}/>
			</div>
		</div>
	)
};

export default Footer;
