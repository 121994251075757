import React from "react";
import Select from "react-select";
const SelectInput = ({
  name,
  data,
  value,
  changer,
  focus,
  placeholder,
  required,
  defaultValue = null,
  small,
  blue,
  noborder
}) => {
  const colourStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: blue ? "#f8f9ff" : "white",
      minHeight: small ? "30px" : "60px",
      border: noborder ? "none" : " 1px solid #9a9a9a"
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: small ? "14px" : "20px",
      lineHeight: small ? "18px" : "25px",
      padding: small ? "0 5px" : "0 10px",
      color: "rgba(62, 76, 89, .4)"
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      fontSize: small ? "14px" : "20px",
      lineHeight: small ? "18px" : "25px",
      color: "#3e4c59",
      padding: small ? "5px" : "10px"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        padding: "20px",
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#2f75f7"
          : isFocused
          ? "#e2f2ff"
          : null,
        color: isDisabled ? "#ccc" : isSelected ? "white" : "#3c5a99",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor:
            !isDisabled && (isSelected ? "transparent" : "#e3f5ff")
        }
      };
    }
  };
  return (
    <Select
      name={name}
      defaultValue={defaultValue}
      value={value}
      onChange={changer}
      placeholder={placeholder}
      options={data}
      required={required}
      styles={colourStyles}
      autoFocus={focus}
    />
  );
};

export default SelectInput;
