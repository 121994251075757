import { Link } from "react-router-dom";
import React, { useState } from "react";

import Footer from "../../components/Footer";
import FullBanner from "../../components/FullBanner";
import ImgDesc from "../../components/ImgDesc";
import Nav from "../../components/Nav";
import TextDesc from "../../components/TextDesc";
import handPhone from "../../assets/handPhone.webp";
import home from "../../assets/home.jpg";
import house from "../../assets/house.jpg";
import penwrite from "../../assets/sign.jpg";
import phone from "../../assets/phone.svg";
import pig from "../../assets/piggy.jpg";
import playBtn from "../../assets/playBtn.svg";
import shake from "../../assets/shake.webp";
import styles from "./index.module.scss";
import videoImage from "../../assets/lady.jpg";

const Home = props => {
  const [modal, setModal] = useState(false);
  return (
    <div className={styles.home}>
      <Nav />
      <div className={styles.main}>
        <div className={styles.first}>
          <div className={styles.side1}>
            <div className={styles.title}>Own a home sooner than you think</div>
            <div className={styles.desc}>
              First homes allows you save towards your dream home in months,its
              quick, flexible and easy.
            </div>
            <div className={styles.buttons}>
              <Link to="" className={styles.dark}>
                GET STARTED
              </Link>
              <Link to="">LEARN MORE</Link>
            </div>
          </div>

          <div className={styles.side2}>
            <div className={styles.shape1} />
            <div className={styles.img}>
              <img src={handPhone} alt="phone in a hand" />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.twoPart}>
        <div className={styles.title}>How it works:</div>
        <ImgDesc
          title="Choose a house type"
          link="/#"
          image={house}
          desc="Contrary to popular belief, Lorem Ipsum is not simply random text.
					It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old."
        />

        <ImgDesc
          right
          title="Set budget and start to save"
          link="/#"
          image={pig}
          desc="Contrary to popular belief, Lorem Ipsum is not simply random text.
					It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old."
        />

        <ImgDesc
          title="Grow credibility"
          link="/#"
          image={shake}
          desc="Contrary to popular belief, Lorem Ipsum is not simply random text.
						It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old."
        />

        <ImgDesc
          right
          title="Own your home"
          link="/#"
          image={home}
          desc="Contrary to popular belief, Lorem Ipsum is not simply random text.
						It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old."
        />
      </div>

      <div className={styles.highlight}>
        <div className={styles.title}>Features you’ll love:</div>
        <TextDesc
          title="Monitor your Money"
          desc="Contrary to popular belief, Lorem Ipsum is not simply random text.
					It has roots in a piece of classical Latin literature from 45 BC,
					making it over 2000 years old."
          image={phone}
        />

        <TextDesc
          right
          title="Bank grade security"
          desc="Contrary to popular belief, Lorem Ipsum is not simply random text.
						It has roots in a piece of classical Latin literature from 45 BC,
						making it over 2000 years old."
          image={phone}
        />
      </div>

      <FullBanner
        title="Still not convinced? Here’s a video:"
        image={videoImage}
        Bottom={() => (
          <button className={styles.noBg} onClick={() => setModal(true)}>
            <img src={playBtn} alt="play button" />
          </button>
        )}
      />
      <FullBanner
        title="Sign up today and qualify for a 100% matching bonus !"
        image={penwrite}
        Bottom={() => (
          <button className={styles.blueBtn}>START YOUR JOURNEY</button>
        )}
      />
      <Footer />

      {modal && (
        <div className={styles.overall}>
          <div className={styles.card}>
            <video
              controls
              src={
                "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4"
              }
            />
            <button className={styles.linkbtn} onClick={() => setModal(false)}>
              EXIT
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
