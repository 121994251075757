import styles from "./index.module.scss";
import React, { useState, useEffect } from "react";
import spinner from "../../../../assets/spinnerblue.svg";
import { useMappedState, useDispatch } from "redux-react-hook";
import SelectInput from "../../../../components/SelectInput";
import {
  getLocations,
  calculateHousing
} from "../../../../store/actions/onboard";
import includesAll from "../../../../utils/includesAll";
import { setNotify } from "../../../../store/actions/notify";

const Plan = () => {
  const [current, setCurrent] = useState(0);
  const dispatch = useDispatch();
  const [selects, setSelects] = useState({});
  const [details, setDetails] = useState({});

  // const { currentUser: user, loading } = useMappedState(({ auth }) => auth);
  const plan = useMappedState(({ onboard }) => onboard.calculation);
  const onboard = useMappedState(({ onboard }) => onboard);

  useEffect(() => {
    setDetails({});
    setSelects({});
  }, [plan]);
  const nextPage = e => {
    e && e.preventDefault();
    if (current < 2) {
      setCurrent(current + 1);
    }
  };

  const HouseTypes = onboard.houseTypes.map(house => ({
    label: house.description,
    value: house.houseType
  }));
  const states = onboard.states.map(({ state }) => ({
    label: state,
    value: state
  }));

  const locations = onboard.locations.map(({ location }) => ({
    label: location,
    value: location
  }));

  const years = [...Array(15).keys()].map(num => ({
    label: `${num + 1} year${num + 1 === 1 ? "" : "s"}`,
    value: num + 1
  }));

  const handleSelect = (data, name) => {
    if (name === "state") {
      setSelects({
        ...selects,
        location: { value: "", label: "" }
      });
      dispatch(getLocations(data.value));
    }
    setSelects({
      ...selects,
      [name]: data
    });

    setDetails({
      ...details,
      [name]: data.value
    });
  };

  const gatherValue = e => {
    e && e.preventDefault();
    setDetails({
      ...details,
      [e.target.name]: e.target.value
    });
  };

  const prevPage = e => {
    e && e.preventDefault();
    if (current >= 1) {
      setCurrent(current - 1);
    }
  };

  const calculateCost = e => {
    e && e.preventDefault();
    if (
      !includesAll(Object.keys(details), [
        "housePrice",
        "houseType",
        "state",
        "location",
        "loanTerm"
      ])
    ) {
      return dispatch(
        setNotify({
          type: "error",
          title: "All fields required",
          body: "Insert all fields correctly"
        })
      );
    }
    dispatch(
      calculateHousing(
        { ...details, housePrice: details.housePrice * 100 },
        nextPage
      )
    );
  };
  const renderPage = {
    0: (
      <div className={styles.main}>
        <div className={styles.top}>
          <div className={styles.title}>Current Housing Plan</div>
          <div className={styles.btns}>
            <div className={styles.btn}>
              <button onClick={nextPage}>edit</button>
            </div>
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>Housing type</div>
          <div className={styles.item}>{plan && plan.houseType}</div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>Location</div>
          <div className={styles.item}>{plan && plan.location}</div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>MONTHLY CONTRIBUTION</div>
          <div className={styles.item}>
            &#8358; {plan && plan.estimatedMonthlyPament.toLocaleString()}
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>ESTIMATED CONTRIBUTION PERIOD</div>
          <div className={styles.item}>
            {plan && plan.savingsDuration} month
            {plan && plan.savingsDuration <= 1 ? "" : "s"}
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>TOTAL CONTRIBUTION</div>
          <div className={styles.item}>
            &#8358; {plan && plan.totalEquityContribution.toLocaleString()}
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>ESTIMATED MATURITY DATE</div>
          <div className={styles.item}>{plan && plan.maturityDate}</div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>TOTAL LOAN AMOUNT</div>
          <div className={styles.item}>
            &#8358; {plan && plan.loanAmount.toLocaleString()}
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>MAXIMUM EQUITY REPAYMENT PERIOD</div>
          <div className={styles.item}>
            {plan && plan.loanTerm} year{plan && plan.loanTerm <= 1 ? "" : "s"}
          </div>
        </div>
      </div>
    ),
    1: (
      <div className={styles.main}>
        <div className={`${styles.top} ${styles.break}`}>
          <div className={styles.title}>Edit Housing Plan</div>
          <div className={styles.btns}>
            {onboard.calculating ? (
              <img
                src={spinner}
                style={{
                  height: "50px"
                }}
                alt=""
              />
            ) : (
              <div className={styles.btn}>
                <button onClick={calculateCost}>Calculate</button>
              </div>
            )}
            <div className={styles.btn}>
              <button onClick={prevPage}>Back</button>
            </div>
          </div>
        </div>

        <div className={styles.form}>
          <div className={styles.select}>
            <label>House Type</label>

            <SelectInput
              changer={data => handleSelect(data, "houseType")}
              blue
              value={selects.houseType}
              noborder
              placeholder="House Type"
              data={HouseTypes}
            />
          </div>
          <div className={styles.select}>
            <label>State</label>

            <SelectInput
              blue
              value={selects.state}
              changer={data => handleSelect(data, "state")}
              noborder
              placeholder="State"
              data={states}
            />
          </div>

          {!onboard.loading && locations.length > 0 && (
            <div className={styles.select}>
              <label>Location</label>

              <SelectInput
                changer={data => handleSelect(data, "location")}
                blue
                value={selects.location}
                noborder
                placeholder="Locations"
                data={locations}
              />
            </div>
          )}
          {onboard.loading && (
            <img
              src={spinner}
              style={{
                height: "50px",
                alignSelf: "center"
              }}
              alt=""
            />
          )}

          <div className={styles.input}>
            <label> House Price</label>
            <input
              type="number"
              name="housePrice"
              placeholder="House Price"
              value={details.housePrice}
              onChange={gatherValue}
            />
          </div>
          <div className={styles.select}>
            <label>Loan Term</label>

            <SelectInput
              changer={data => handleSelect(data, "loanTerm")}
              blue
              value={selects.loanTerm}
              noborder
              placeholder="Select Loan Term"
              data={years}
            />
          </div>
        </div>
      </div>
    ),
    2: (
      <div className={styles.main}>
        <div className={`${styles.top} ${styles.break}`}>
          <div className={styles.title}>Calculated Housing Plan</div>
          <div className={styles.btns}>
            <div className={styles.btn}>
              <button onClick={prevPage}>Recaculate</button>
            </div>
            <div className={styles.btn}>
              <button onClick={() => setCurrent(0)}>Exit</button>
            </div>
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>Housing type</div>
          <div className={styles.item}>{plan && plan.houseType}</div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>Location</div>
          <div className={styles.item}>{plan && plan.location}</div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>MONTHLY CONTRIBUTION</div>
          <div className={styles.item}>
            &#8358; {plan && plan.estimatedMonthlyPament.toLocaleString()}
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>ESTIMATED CONTRIBUTION PERIOD</div>
          <div className={styles.item}>
            {plan && plan.savingsDuration} month
            {plan && plan.savingsDuration <= 1 ? "" : "s"}
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>TOTAL CONTRIBUTION</div>
          <div className={styles.item}>
            &#8358; {plan && plan.totalEquityContribution.toLocaleString()}
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>ESTIMATED MATURITY DATE</div>
          <div className={styles.item}>{plan && plan.maturityDate}</div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>TOTAL LOAN AMOUNT</div>
          <div className={styles.item}>
            &#8358; {plan && plan.loanAmount.toLocaleString()}
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>MAXIMUM EQUITY REPAYMENT PERIOD</div>
          <div className={styles.item}>
            {plan && plan.loanTerm} year{plan && plan.loanTerm <= 1 ? "" : "s"}
          </div>
        </div>
      </div>
    )
  };
  return <div className={styles.plan}>{renderPage[current]}</div>;
};

export default Plan;
