import React from "react";

import Footer from "../../components/Footer";
import Nav from "../../components/Nav";
import styles from "./index.module.scss";
import article1 from "../../assets/article1.webp";
import article2 from "../../assets/article2.webp";
import banner from "../../assets/banner.svg";
import rise from "../../assets/rise.svg";

const About = props => {
  return (
    <div className={styles.about}>
      <Nav />
      <div className={styles.main}>
        <div className={styles.text}>
          We believe the best experience always wins
        </div>
        <div className={styles.decor}>
          <img src={rise} alt="decor" />
        </div>
      </div>

      <div className={styles.others}>
        <div className={styles.item}>
          <div className={styles.image}>
            <img src={article2} alt="" />
          </div>

          <div className={styles.text}>
            <div className={styles.title}>We’re makers that get results</div>
            <div className={styles.desc}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.{" "}
            </div>
          </div>
        </div>
        <div className={styles.item2}>
          <div className={styles.text}>
            <div className={styles.title}>
              Real-time data driven metrics for the win
            </div>
            <div className={styles.desc}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.{" "}
            </div>
          </div>
          <div className={styles.image}>
            <img src={article1} alt="" />
          </div>
        </div>

        <div className={styles.footDesc}>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book
        </div>
        <div
          className={styles.banner}
          style={{
            background: `url(${banner})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
          }}
        />
        <div className={styles.bottom}>
          <div className={styles.text}>What makes us different?</div>
          <div className={styles.gridSec}>
            <div className={styles.item}>
              <div className={styles.title}>
                <div className={styles.big}>01</div>
                <div>Bank grade security</div>
              </div>
              <div className={styles.desc}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.title}>
                <div className={styles.big}>02</div>
                <div>Flexibility</div>
              </div>
              <div className={styles.desc}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.title}>
                <div className={styles.big}>03</div>
                <div>Reliablility</div>
              </div>
              <div className={styles.desc}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.title}>
                <div className={styles.big}>04</div>
                <div>Transparency</div>
              </div>
              <div className={styles.desc}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default About;
