import { combineReducers } from "redux";

import auth from "./auth";
import onboard from "./onboard";
import notify from "./notify";
import card from "./card";
import wallet from "./wallet";

export default combineReducers({
  wallet,
  card,
  notify,
  onboard,
  auth
});
