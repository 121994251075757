import styles from "./index.module.scss";
import React, { useState } from "react";
import spinner from "../../../../assets/spinnerblue.svg";
import bulb from "../../../../assets/bulb.svg";
import { useDispatch, useMappedState } from "redux-react-hook";
import SelectInput from "../../../../components/SelectInput";
import { setNotify } from "../../../../store/actions/notify";
import { verifyBank, setWithdrawal } from "../../../../store/actions/wallet";
import includesAll from "../../../../utils/includesAll";
import Table from "../../../../components/Table";
import DropDown from "../../../../components/DropDown";

const Withdraw = () => {
  const dispatch = useDispatch();
  const {
    banks,
    verifying,
    verified,
    withdrawing,
    withdrawals
  } = useMappedState(({ wallet }) => wallet);
  let timeOut = null;
  const [selects, setSelects] = useState({});
  const [details, setDetails] = useState({});

  const withdrawtype = [
    {
      label: "Instant Withdrawal",
      value: "instant"
    },
    {
      label: "Delayed Withdrawal",
      value: "delayed"
    }
  ];

  const bankOptions = banks.map(bank => ({
    value: bank.Code,
    label: bank.Name
  }));

  const handleSelect = (data, name) => {
    setSelects({
      ...selects,
      [name]: data
    });

    setDetails({
      ...details,
      [name]: data.value
    });
  };

  const handleInputs = e => {
    e && e.preventDefault();

    if (e.target.name === "accountNumber") {
      const accountNumber = e.target.value;
      const name = e.target.name;
      if (timeOut) {
        clearTimeout(timeOut);
      }
      timeOut = setTimeout(() => {
        timeOut = null;
        dispatch(
          verifyBank({
            accountNumber: accountNumber,
            bankcode: details.bankcode
          })
        );
        return setDetails({
          ...details,
          [name]: accountNumber
        });
      }, 2000);
    } else {
      return setDetails({
        ...details,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleSubmit = e => {
    e && e.preventDefault();
    if (
      !includesAll(Object.keys(details), [
        "type",
        "amount",
        "returnDate",
        "bankcode",
        "bvn",
        "accountNumber"
      ])
    ) {
      return dispatch(
        setNotify({
          type: "error",
          title: "All fields required",
          body: "Insert all fields correctly"
        })
      );
    }
    return dispatch(setWithdrawal(details));
  };

  return (
    <div className={styles.withdraw}>
      <div className={styles.group}>
        <div className={styles.left}>
          <div className={styles.title}>Withdraw Funds</div>
          <img src={bulb} alt="bulb" />
          <div className={styles.sub}>
            You can withdraw up to 5% of your monthly equity which will be
            repayed before the end of the month with 10% interest on the amount
            withdrawn.
          </div>
          <div className={styles.instruct}>
            How much do you want to withdraw?
          </div>
          <div className={styles.form}>
            <form onSubmit={handleSubmit}>
              <SelectInput
                changer={data => handleSelect(data, "type")}
                small
                blue
                value={selects.type}
                noborder
                placeholder="Withdrawal Type"
                data={withdrawtype}
              />
              <input
                onChange={handleInputs}
                type="number"
                name="amount"
                placeholder="₦ 60,000"
              />
              <input
                onChange={handleInputs}
                type="text"
                name="returnDate"
                onFocus={e => (e.target.type = "date")}
                onBlur={e => (e.target.type = "text")}
                placeholder="Select Repayment Date"
              />
              <SelectInput
                changer={data => handleSelect(data, "bankcode")}
                small
                blue
                value={selects.bankcode}
                noborder
                placeholder="Select Bank"
                data={bankOptions}
              />
              {selects.bankcode && (
                <input
                  onChange={handleInputs}
                  type="number"
                  name="accountNumber"
                  placeholder="Enter your account number"
                />
              )}
              {verified && verified.accountname && (
                <>
                  <input type="text" value={verified.accountname} disabled />

                  <input
                    onChange={handleInputs}
                    type="number"
                    name="bvn"
                    placeholder="Enter BVN "
                  />
                </>
              )}
              {verifying && <img src={spinner} alt="loader" />}
              {withdrawing && <img src={spinner} alt="loader" />}
              {details.bvn && !withdrawing && (
                <input type="submit" value="Confirm" />
              )}
            </form>
          </div>
        </div>
        <div className={styles.right}>
          <div>
            <DropDown
              data={[{ name: "Pending", action: () => alert("here") }]}
            />
          </div>
          <div className={styles.table}>
            <Table
              title="Withdrawal History"
              showTitle
              data={withdrawals}
              header={["Amount", "Date", "reference", "status"]}
              conditions={{
                boolean: {
                  true: "Repaid",
                  false: "Not repaid"
                },
                double: "status",
                badge: "SUCCESSFUL"
              }}
              dataType={[
                {
                  amount: "double"
                },
                {
                  withdrawalDate: "text"
                },
                {
                  reference: "truncate"
                },
                {
                  hasPaidBack: "boolean"
                }
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
