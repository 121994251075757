import React from "react";

import Panel from "../../../components/Panel";
import Profile from "./Profile";
import Withdraw from "./Withdraw";
import Tabs from "../../../components/Tabs";
import styles from "./index.module.scss";
import Plan from "./Plan";
import Upload from "./Uploads";

const Account = () => {
  return (
    <div className={styles.account}>
      <div className={styles.title}>Account</div>
      <Tabs>
        <Panel title="Profile">
          <Profile />
        </Panel>

        <Panel title="Uploaded Files">
          <Upload />
        </Panel>
        <Panel title="Modify Plan">
          <Plan />
        </Panel>
        <Panel title="Withdraw Funds">
          <Withdraw />
        </Panel>

        <Panel title="Support &amp; Feedback">
          <div>{}</div>
        </Panel>
      </Tabs>
    </div>
  );
};

export default Account;
