import React from "react";

import styles from "./index.module.scss";

const Verify = () => {
  return (
    <div className={styles.verify}>
      <div> Click Button to verify the Card</div>
    </div>
  );
};

export default Verify;
