import React from 'react'
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import arrow from '../../assets/arrow.svg';

const ImgDesc = ({ title, desc, link, image, right}) => {
	return(
		<div className={styles.group}>
			{
				!right && <div className={styles.side}>
				<div className={styles.shape1}/>
				<img src={image} alt="desc"/>
			</div>
			}
		<div className={styles.mid}>
			<div>
				<div className={styles.title}>{title}</div>
				<div className={styles.desc}>{desc}</div>
				<Link to={link}>See more <img src={arrow} alt="arrow"/> </Link>
			</div>
		</div>

		{
			right && <div className={styles.side}>
			<div className={styles.shape2}/>
			<img src={image} alt="desc"/>
		</div>
		}
		</div>
	)
};

export default ImgDesc;
