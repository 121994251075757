import React, { useState, useEffect } from "react";

import Footer from "../../components/Footer";
import Nav from "../../components/Nav";
import papers from "../../assets/papers.jpg";
import article1 from "../../assets/article1.webp";
import article2 from "../../assets/article2.webp";
import article3 from "../../assets/books.jpg";
import styles from "./index.module.scss";

const Blog = ({ history }) => {
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(1);

  const slider = {
    1: papers,
    2: article3,
    3: article1
  };
  useEffect(() => {
    let interval;
    interval = setTimeout(() => {
      if (count >= 3) {
        return setCount(1);
      }
      return setCount(count + 1);
    }, 5000);

    return () => {
      clearTimeout(interval);
    };
  }, [count]);

  const onchange = e => setSearch(e.target.value);

  const articles = [
    {
      image: article1,
      title: "Monitor your mortgage from your phone ",
      desc:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
      date: "May 15, 2019",
      publisher: "Company News"
    },
    {
      image: article2,
      title: "Introducing our new CFO, Olusola Martins",
      desc:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
      date: "May 15, 2019",
      publisher: "Company News"
    },
    {
      image: article3,
      title:
        "N 3 million awarded to student aiming to revolutionise the real-estate.",
      desc:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
      date: "May 15, 2019",
      publisher: "Company News"
    },
    {
      image: papers,
      title: "Monitor your mortgage from your phone ",
      desc:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
      date: "May 15, 2019",
      publisher: "Company News"
    },
    {
      image: article3,
      title: "Introducing our new CFO, Olusola Martins",
      desc:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
      date: "May 15, 2019",
      publisher: "Company News"
    },
    {
      image: article2,
      title:
        "N 3 million awarded to student aiming to revolutionise the real-estate.",
      desc:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
      date: "May 15, 2019",
      publisher: "Company News"
    },
    {
      image: article3,
      title: "Monitor your mortgage from your phone ",
      desc:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
      date: "May 15, 2019",
      publisher: "Company News"
    },
    {
      image: article1,
      title: "Introducing our new CFO, Olusola Martins",
      desc:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
      date: "May 15, 2019",
      publisher: "Company News"
    },
    {
      image: papers,
      title:
        "N 3 million awarded to student aiming to revolutionise the real-estate.",
      desc:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
      date: "May 15, 2019",
      publisher: "Company News"
    }
  ];
  return (
    <div className={styles.blog}>
      <Nav light />
      <div className={styles.main}>
        <div
          className={styles.back}
          style={{
            backgroundImage: `url(${slider[count]})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center"
          }}
        >
          <div>
            <div className={styles.title}>
              How to monitor your mortgage with your phone
            </div>
            <div>
              <button
                className={styles.blueBtn}
                onClick={() => history.push("/blog/1")}
              >
                READ MORE
              </button>
            </div>
          </div>
        </div>
        <div className={styles.searchbar}>
          <div className={styles.text}>Latest news</div>
          <div>
            <form>
              <input
                type="search"
                name="search"
                value={search}
                onChange={onchange}
                placeholder="What are you looking for ?"
              />
              <input type="submit" value="SEARCH" />
            </form>
          </div>
        </div>
      </div>
      <div className={styles.articles}>
        {articles &&
          articles.map((article, i) => (
            <div key={i} className={styles.article}>
              <div className={styles.image}>
                <img src={article.image} alt="article" />
              </div>
              <div className={styles.date}>
                {article.date} in {article.publisher}
              </div>
              <div className={styles.title}>{article.title}</div>
              <div className={styles.desc}>{article.desc}</div>
            </div>
          ))}
      </div>
      <div className={styles.loadmore}>
        <button className={styles.blueBtn}>LOAD MORE</button>
      </div>

      <Footer />
    </div>
  );
};

export default Blog;
