import Axios from "axios";

import {
  AUTH_USER_REQUEST,
  AUTH_USER_ERROR,
  AUTH_USER_SUCCESS,
  LOGOUT_USER,
  UPLOAD_REQUEST,
  UPLOAD_SUCCESS
} from "./types/users";
import { setNotify } from "./notify";
import { getStates, getHouseTypes, fetchCalculation } from "./onboard";
import {
  getContratual,
  getDeposits,
  getWithdrawal,
  getBanks,
  getContribution,
  getVoluntary
} from "./wallet";
import { allCards } from "./card";

const authUserRequest = state => ({
  type: AUTH_USER_REQUEST,
  state
});
const uploadRequest = state => ({
  type: UPLOAD_REQUEST,
  state
});

const authUserError = error => ({
  type: AUTH_USER_ERROR,
  error
});

export const authUserSuccess = user => ({
  type: AUTH_USER_SUCCESS,
  user
});

export const uploadSuccess = files => ({
  type: UPLOAD_SUCCESS,
  files
});

const logoutUserSuccess = () => ({
  type: LOGOUT_USER
});

export const loginUser = (details, history) => dispatch => {
  dispatch(authUserRequest(true));
  return Axios.post("/login", {
    ...details
  })
    .then(async ({ data: { data } }) => {
      Axios.defaults.headers.common["x-access-token"] = data.token;
      localStorage.setItem(
        "user",
        JSON.stringify({ ...data.user, token: data.token })
      );
      localStorage.setItem("token", data.token);
      dispatch(authUserSuccess({ ...data.user, token: data.token }));
      dispatch(refreshUser());
      dispatch(getStates());
      dispatch(getHouseTypes());
      dispatch(getContratual());
      dispatch(getVoluntary());
      dispatch(getContribution());
      dispatch(getDeposits());
      dispatch(allCards());
      dispatch(getFiles());
      dispatch(getWithdrawal());
      dispatch(fetchCalculation());
      dispatch(getBanks());
      dispatch(
        setNotify({
          title: "Success",
          body: "Logged in successfully",
          type: "success"
        })
      );
      return history && history.push("/dashboard");
    })
    .catch(error => {
      if (error.response) {
        dispatch(authUserError(error.response.data));
        return dispatch(
          setNotify({
            title: "Error",
            body: error.response.data.message,
            type: "error"
          })
        );
      }
    });
};

export const sendOtp = (details, action) => dispatch => {
  dispatch(authUserRequest(true));
  return Axios.post("/otp/validate", {
    ...details
  })
    .then(async ({ data }) => {
      dispatch(authUserRequest(false));
      dispatch(
        setNotify({
          title: "Success",
          body: data.message,
          type: "success"
        })
      );
      dispatch(refreshUser());
      return action && action();
    })
    .catch(error => {
      dispatch(authUserRequest(false));
      if (error.response) {
        dispatch(authUserError(error.response.data));
        return dispatch(
          setNotify({
            title: "Error",
            body: error.response.data.message,
            type: "error"
          })
        );
      }
    });
};

export const setPassword = (passwords, action) => dispatch => {
  dispatch(authUserRequest(true));
  return Axios.post("/password", {
    ...passwords
  })
    .then(async ({ data }) => {
      dispatch(authUserRequest(false));
      dispatch(
        setNotify({
          title: "Success",
          body: data.message,
          type: "success"
        })
      );
      dispatch(refreshUser());
      return action && action();
    })
    .catch(error => {
      dispatch(authUserRequest(false));
      if (error.response) {
        dispatch(authUserError(error.response.data));
        return dispatch(
          setNotify({
            title: "Error",
            body: error.response.data.message,
            type: "error"
          })
        );
      }
    });
};

export const reSendOtp = () => dispatch => {
  dispatch(authUserRequest(true));
  return Axios.get("/otp/resend")
    .then(async ({ data }) => {
      dispatch(authUserRequest(false));
      dispatch(
        setNotify({
          title: "Success",
          body: data.message,
          type: "success"
        })
      );
    })
    .catch(error => {
      dispatch(authUserRequest(false));
      if (error.response) {
        dispatch(authUserError(error.response.data));
        return dispatch(
          setNotify({
            title: "Error",
            body: error.response.data.message,
            type: "error"
          })
        );
      }
    });
};

export const sendReferrals = emails => dispatch => {
  return Axios.post("/referral", {
    emails
  })
    .then(async ({ data }) => {
      dispatch(
        setNotify({
          title: "Success",
          body: data.message,
          type: "success"
        })
      );
    })
    .catch(error => {
      if (error.response) {
        return dispatch(
          setNotify({
            title: "Error",
            body: error.response.data.message,
            type: "error"
          })
        );
      }
    });
};

export const signupUser = (details, history) => dispatch => {
  dispatch(authUserRequest(true));
  return Axios.post("/user", {
    ...details
  })
    .then(async ({ data }) => {
      Axios.defaults.headers.common["x-access-token"] = data.token;
      localStorage.setItem("user", JSON.stringify(data));
      localStorage.setItem("token", data.token);
      dispatch(authUserSuccess({ ...data }));
      dispatch(
        setNotify({
          title: "Signed in Sucessfully",
          body: data.message,
          type: "success"
        })
      );
      return history && history.push("/onboarding");
    })
    .catch(error => {
      if (error.response) {
        dispatch(authUserError(error.response.data));
        if (error.response.data.messages) {
          error.response.data.messages.map(message => {
            return dispatch(
              setNotify({
                title: "Error",
                body: message,
                type: "error"
              })
            );
          });
        } else {
          return dispatch(
            setNotify({
              title: "Error",
              body: error.response.data.message,
              type: "error"
            })
          );
        }
      }
    });
};

export const logoutUser = history => async dispatch => {
  try {
    localStorage.clear();
    dispatch(logoutUserSuccess());
  } catch (error) {}
};

export const refreshUser = () => dispatch => {
  dispatch(authUserRequest(true));
  return Axios.get("/user")
    .then(async ({ data: { data, hasPassword } }) => {
      console.log({ ...data, hasPassword });

      localStorage.setItem("user", JSON.stringify({ ...data, hasPassword }));
      dispatch(authUserSuccess({ ...data, hasPassword }));
    })
    .catch(error => {
      if (error.message && error.message === "Network Error") {
        dispatch(
          setNotify({
            type: "error",
            title: error.message,
            body: "Please connect to the Internet"
          })
        );
      }
      dispatch(authUserError(error));
    });
};

export const uploadImage = picture => dispatch => {
  dispatch(authUserRequest(true));
  let formData = new FormData();
  formData.set("picture", picture);
  return Axios.post("/upload", formData)
    .then(({ data }) => {
      dispatch(authUserRequest(false));

      dispatch(
        setNotify({
          title: "Profile Image Uploaded",
          body: data.message,
          type: "success"
        })
      );
      dispatch(refreshUser());
    })
    .catch(error => {
      dispatch(authUserError(error));
    });
};

export const uploadFile = ({ document, documentName }, action) => dispatch => {
  dispatch(uploadRequest(true));
  let formData = new FormData();
  formData.set("document", document);
  formData.set("documentName", documentName);
  return Axios.post("/user/uploadfile", formData)
    .then(({ data }) => {
      console.log(data);
      dispatch(uploadRequest(false));

      dispatch(
        setNotify({
          title: "File Image Uploaded",
          body: data.message,
          type: "success"
        })
      );
      action && action();
      dispatch(getFiles());
    })
    .catch(error => {
      dispatch(uploadRequest(false));
    });
};

export const getFiles = () => dispatch => {
  return Axios.get("/user/uploadfile")
    .then(({ data: { data: { files } } }) => {
      console.log("files", files);
      dispatch(uploadSuccess(files));
    })
    .catch(error => {
      return null;
    });
};
