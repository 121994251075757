import React from 'react';

import Footer from '../../components/Footer';
import navigator from '../../assets/navigator.svg';
import mail from '../../assets/mail.svg';
import map from '../../assets/map.svg';
import phoneicon from '../../assets/phoneicon.svg';
import Nav from '../../components/Nav';
import styles from './index.module.scss';

const Contact = (props) => {
	return(
		<div className={styles.contact}>
			<Nav />
			<div className={styles.main}>
				<div>
					<div className={styles.title}>Contact Us</div>
				<div className={styles.places}>
					<div className={styles.place}>
						<div><img src={navigator} alt="icon" /></div>
					<div>154 Bamgbose Street, Surulere, Lagos.</div>
					</div>

					<div className={styles.place}>
						<div><img src={mail} alt="icon" /></div>
					<div>hello@firsthomes.com</div>
					</div>

					<div className={styles.place}>
						<div><img src={phoneicon} alt="icon" /></div>
					<div>+234 802 079 4242 <br /> +234 803 079 4242</div>
					</div>
				</div>
				</div>

				<div className={styles.formGroup}>
					<div className={styles.map}><img src={map} alt="map" /></div>
					<div  className={styles.form}>
						<div className={styles.text}>Get in touch</div>
					<div className={styles.subtext}>Ask us anything and we will guide you to your home.</div>
						<form>
							<div className={styles.split}>
								<div className={styles.group}>
									<label>First name</label>
								<input type="text" name="firstname"  />
								</div>

								<div className={styles.group}>
									<label>Last name</label>
								<input type="text" name="firstname"  />
								</div>
							</div>

							<div className={styles.split}>
								<div className={styles.group}>
									<label>Email address</label>
								<input type="email" name="firstname"  />
								</div>

								<div className={styles.group}>
									<label>Phone number</label>
								<input type="phone" name="firstname"  />
								</div>
							</div>

							<div className={styles.group}>
								<label>Message</label>
							<textarea name="message" cols="" rows="10"></textarea>
							</div>
							<div className={styles.btn}>
								<button type="button">SUBMIT</button></div>
						</form>
					</div>
				</div>
				</div>
				<div className={styles.padbottom}></div>

		<Footer />
		</div>
	)
};

export default Contact;
