import styles from "./index.module.scss";
import React from "react";
import spinner from "../../../../assets/spinner.svg";
import { useDispatch, useMappedState } from "redux-react-hook";
import { uploadImage } from "../../../../store/actions/auth";

const Profile = () => {
  const dispatch = useDispatch();
  const { currentUser: user, loading } = useMappedState(({ auth }) => auth);
  const plan = useMappedState(({ onboard }) => onboard.calculation);
  const contributions = useMappedState(({ wallet }) => wallet.contributions);

  const upload = e => {
    return dispatch(uploadImage(e.target.files[0]));
  };

  return (
    <div className={styles.profile}>
      <div className={styles.main}>
        <div className={styles.top}>
          <div className={styles.image}>
            <div className={styles.img}>
              <img src={user.profilePicture && user.profilePicture} alt="" />
              {loading && (
                <img src={spinner} alt="" className={styles.loading} />
              )}
            </div>
            <input type="file" onChange={upload} />
          </div>
          <div className={styles.side}>
            <div className={styles.row}>
              <div className={styles.grp}>
                <div className={styles.lft}>First Name</div>
                <div className={styles.rgt}>{user && user.firstname}</div>
              </div>

              <div className={styles.grp}>
                <div className={styles.lft}>Points Score</div>
                <div className={styles.rgt__b}>
                  {contributions.morgagePoints}
                </div>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.grp}>
                <div className={styles.lft}>Last Name</div>
                <div className={styles.rgt}>{user && user.lastname}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.list}>
          <div className={styles.head}>D.O.B</div>
          <div className={styles.item}>
            {user && new Date(user.dateOfBirth).toDateString()}
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>Email</div>
          <div className={styles.item}>{user && user.email}</div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>Phone</div>
          <div className={styles.item}>{user && user.phoneNumber}</div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>Housing type</div>
          <div className={styles.item}>{plan && plan.houseType}</div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>Location</div>
          <div className={styles.item}>{plan && plan.location}</div>
        </div>

        <div className={styles.list}>
          <div className={styles.head}>Estimated Maturity Date</div>
          <div className={styles.item}>{plan && plan.maturityDate}</div>
        </div>
      </div>
      <div className={styles.others}>
        <div className={styles.list}>
          <div className={styles.title}>Next of Kin Details</div>
          <div className={styles.btn}>
            <button>edit</button>
          </div>
        </div>
        <div className={styles.list}>
          <div className={styles.head}>Name</div>
          <div className={styles.item}>{}</div>
        </div>
        <div className={styles.list}>
          <div className={styles.head}>Relationship</div>
          <div className={styles.item}>{}</div>
        </div>
        <div className={styles.list}>
          <div className={styles.head}>Phone Number</div>
          <div className={styles.item}>{}</div>
        </div>
        <div className={styles.list}>
          <div className={styles.head}>Email Address</div>
          <div className={styles.item}>{}</div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
