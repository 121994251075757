import { authUserSuccess, refreshUser, getFiles } from "../store/actions/auth";
import Axios from "axios";
import {
  getStates,
  getHouseTypes,
  fetchCalculation
} from "../store/actions/onboard";
import {
  getContratual,
  getContribution,
  getDeposits,
  getWithdrawal,
  getBanks,
  getVoluntary
} from "../store/actions/wallet";
import { allCards } from "../store/actions/card";

const setUser = store => {
  Axios.defaults.baseURL = "https://staging.firsthomesafrica.com/api/v2/";
  let user = null;
  if (localStorage.user) {
    const token = localStorage.getItem("token");
    if (localStorage.getItem("user")) {
      Axios.defaults.headers.common["x-access-token"] = token;
      user = JSON.parse(localStorage.getItem("user"));

      store.dispatch(authUserSuccess(user));
      store.dispatch(refreshUser());
      store.dispatch(getStates());
      store.dispatch(getHouseTypes());
      store.dispatch(getContratual());
      store.dispatch(getVoluntary());
      store.dispatch(getContribution());
      store.dispatch(getDeposits());
      store.dispatch(allCards());
      store.dispatch(getFiles());
      store.dispatch(getWithdrawal());
      store.dispatch(fetchCalculation());
      store.dispatch(getBanks());
    }
  }
};

export default setUser;
