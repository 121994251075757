import React from "react";

import Footer from "../../components/Footer";
import Nav from "../../components/Nav";
import papers from "../../assets/papers.jpg";
import article1 from "../../assets/article1.webp";
import article2 from "../../assets/article2.webp";
import article3 from "../../assets/books.jpg";
import styles from "./index.module.scss";

const Blog = props => {
  return (
    <div className={styles.blog}>
      <Nav light />
      <div className={styles.main}>
        <div
          className={styles.back}
          style={{
            backgroundImage: `url(${papers})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center"
          }}
        >
          <div>
            <div className={styles.title}>
              How to monitor your mortgage with your phone
            </div>
            <div className={styles.date}>July 25, 2019</div>
            <div className={styles.down}>
              <i className="lni-chevron-down" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.article}>
        <sub>What is Lorem Ipsum?</sub>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </p>
        <p>
          Contrary to popular belief, Lorem Ipsum is not simply random text. It
          has roots in a piece of classical Latin literature from 45 BC, making
          it over 2000 years old. Richard McClintock, a Latin professor at
          Hampden-Sydney College in Virginia, looked up one of the more obscure
          Latin words, consectetur, from a Lorem Ipsum passage, and going
          through the cites of the word in classical literature, discovered the
          undoubtable source. Lorem Ipsum comes from sections 1.10.32 and
          1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and
          Evil) by Cicero, written in 45 BC. This book is a treatise on the
          theory of ethics, very popular during the Renaissance. The first line
          of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in
          section 1.10.32.
        </p>

        <div className={styles.images}>
          <div className={styles.img}>
            <div className={styles.shape} />
            <img src={article3} alt="" />
          </div>
          <div className={styles.img}>
            <div className={styles.shape} />
            <img src={article2} alt="" />
          </div>
        </div>

        <sub>What is Lorem Ipsum?</sub>

        <p>
          There are many variations of passages of Lorem Ipsum available, but
          the majority have suffered alteration in some form, by injected
          humour, or randomised words which don't look even slightly believable.
          If you are going to use a passage of Lorem Ipsum, you need to be sure
          there isn't anything embarrassing hidden in the middle of text. All
          the Lorem Ipsum generators on the Internet tend to repeat predefined
          chunks as necessary, making this the first true generator on the
          Internet. It uses a dictionary of over 200 Latin words, combined with
          a handful of model sentence structures, to generate Lorem Ipsum which
          looks reasonable. The generated Lorem Ipsum is therefore always free
          from repetition, injected humour, or non-characteristic words etc.
        </p>
        <p>
          There are many variations of passages of Lorem Ipsum available, but
          the majority have suffered alteration in some form, by injected
          humour, or randomised words which don't look even slightly believable.
          If you are going to use a passage of Lorem Ipsum, you need to be sure
          there isn't anything embarrassing hidden in the middle of text. All
          the Lorem Ipsum generators on the Internet tend to repeat predefined
          chunks as necessary, making this the first true generator on the
          Internet. It uses a dictionary of over 200 Latin words, combined with
          a handful of model sentence structures, to generate Lorem Ipsum which
          looks reasonable. The generated Lorem Ipsum is therefore always free
          from repetition, injected humour, or non-characteristic words etc.
        </p>
      </div>

      <div className={styles.recommend}>
        <div className={styles.title}>Recommended reads</div>
        <div className={styles.list}>
          <div
            style={{
              backgroundImage: `url(${papers})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center"
            }}
          >
            Lorem Ipsum is awesome to use for this
          </div>
          <div
            style={{
              backgroundImage: `url(${article1})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center"
            }}
          >
            Lorem Ipsum is awesome to use for this
          </div>
          <div
            style={{
              backgroundImage: `url(${article2})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center"
            }}
          >
            Lorem Ipsum is awesome to use for this
          </div>
          <div
            style={{
              backgroundImage: `url(${article3})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center"
            }}
          >
            Lorem Ipsum is awesome to use for this
          </div>
          <div
            style={{
              backgroundImage: `url(${papers})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center"
            }}
          >
            Lorem Ipsum is awesome to use for this
          </div>
          <div
            style={{
              backgroundImage: `url(${article2})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center"
            }}
          >
            Lorem Ipsum is awesome to use for this
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Blog;
