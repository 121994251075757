export default function filterArray(data, types) {
  let array = data;

  types &&
    Object.keys(types).map(val => {
      const type = types[val];
      const key = Object.keys(type)[0];
      if (key === "value") {
        array = array.filter(datum => datum[type[key][0]] === type[key][1]);
      }
      if (key === "desc") {
        array = array.sort((a, b) => a[type[key]] - b[type[key]]);
      }

      if (key === "asc") {
        array = array.sort((a, b) => b[type[key]] - a[type[key]]);
      }

      if (key === "ascDate") {
        array = array.sort(
          (a, b) => new Date(b[type[key]]) - new Date(a[type[key]])
        );
      }
      if (key === "descDate") {
        array = array.sort(
          (a, b) => new Date(a[type[key]]) - new Date(b[type[key]])
        );
      }
      if (key === "default") {
        array = data;
      }
      return null;
    });

  return array;
}
