import styles from "./index.module.scss";
import React, { useState } from "react";
import spinner from "../../../../assets/spinnerblue.svg";
import { useDispatch, useMappedState } from "redux-react-hook";
import { setNotify } from "../../../../store/actions/notify";
import includesAll from "../../../../utils/includesAll";
import Table from "../../../../components/Table";
import { uploadFile } from "../../../../store/actions/auth";

const Upload = () => {
  const [modal, setModal] = useState(false);
  const [detail, setDetail] = useState({});

  const dispatch = useDispatch();
  const { uploading, files } = useMappedState(({ auth }) => auth);

  const handleSubmit = e => {
    e && e.preventDefault();
    if (!includesAll(Object.keys(detail), ["document", "documentName"])) {
      return dispatch(
        setNotify({
          type: "error",
          title: "All fields required",
          body: "Insert all fields correctly"
        })
      );
    }
    return dispatch(uploadFile(detail, () => setModal(false)));
  };

  const upload = e => {
    e && e.preventDefault();
    setDetail({
      ...detail,
      document: e.target.files[0]
    });
  };

  const changeVal = e => {
    e && e.preventDefault();
    setDetail({
      ...detail,
      documentName: e.target.value
    });
  };
  return (
    <div className={styles.upload}>
      <div className={styles.topPart}>
        <div>{}</div>
        <div>
          <button onClick={() => setModal(true)}>
            Upload new file <i className="lni-chevron-down" />
          </button>
        </div>
      </div>
      <div className={styles.table}>
        <Table
          data={files}
          dataType={[
            { documentName: "text" },
            { dateAdded: "text" },
            { document: "action" }
          ]}
          header={["Name", "Date Added", "Document"]}
        />
      </div>
      {modal && (
        <div className={styles.modal}>
          <div className={styles.back} onClick={() => setModal(false)} />
          <div className={styles.content}>
            <div className={styles.title}>Upload File</div>
            <div className={styles.sub}>upload a supporting document</div>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Name of Document"
                onChange={changeVal}
                value={detail.documentName || ""}
              />
              <label htmlFor="file">
                {(detail.document && detail.document.name) ||
                  "Choose file to upload"}
              </label>
              <input
                type="file"
                placeholder="Choose your file"
                id="file"
                onChange={upload}
              />
              {uploading ? (
                <img src={spinner} alt="" height="50" />
              ) : (
                <input type="submit" value="Upload" />
              )}
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Upload;
