import React from 'react'
import styles from './index.module.scss';

const TextDesc = ({ title, desc, image, right}) => {
	return(
		<div className={styles.textDesc}>
			{
				!right && <div className={styles.side}>
				<img src={image} alt="desc"/>
			</div>
			}
		<div className={`${styles.mid} ${right && styles.right}`}>
			<div>
				<div className={styles.title}>{title}</div>
				<div className={styles.desc}>{desc}</div>
			</div>
		</div>

		{
			right && <div className={styles.side}>
			<img src={image} alt="desc"/>
		</div>
		}
		</div>
	)
};

export default TextDesc;
