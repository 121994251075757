import { Link } from "react-router-dom";
import React, { useState } from "react";
import spinner from "../../assets/spinner.svg";

import Nav from "../../components/Nav";
import styles from "./index.module.scss";
import { signupUser } from "../../store/actions/auth";
import { useDispatch } from "redux-react-hook";
import { useMappedState } from "redux-react-hook";
const Signup = ({ history }) => {
  const [details, setDetails] = useState({});
  const dispatch = useDispatch();
  const onChange = e => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value
    });
  };
  const user = useMappedState(state => state.auth);

  const submit = e => {
    e.preventDefault();
    dispatch(signupUser(details, history));
  };

  return (
    <div className={styles.signup}>
      <Nav onlyHome />
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.banner}>
            <div>
              <div className={styles.text}>Getting started</div>
              <div className={styles.text2}>
                Use your Google or Facebook account to easily get started, or
                fill out the form.
              </div>
              <div className={styles.text3}>
                By signing up, you agree to our{" "}
                <Link to="#">Terms of Service</Link> and{" "}
                <Link to="#">Privacy Policy.</Link>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.formGroup}>
            <div className={styles.head}>
              Already have an account? <Link to="/signin">Sign in</Link>
            </div>
            {/* <div className={styles.headDash}>
              <hr />
              <p>Sign up with</p>{" "}
            </div>
            <div className={styles.social}>
              <button className={styles.googleBtn}>
                {" "}
                <i className="lni-google" /> Google
              </button>
              <button className={styles.faceBtn}>
                <i className="lni-facebook-filled" /> Facebook
              </button>
            </div>
            <div className={styles.headDash}>
              <hr />
              <p>Or </p>
            </div> */}

            <form onSubmit={submit}>
              <input
                type="text"
                name="firstname"
                onChange={onChange}
                placeholder="First name"
              />
              <input
                type="text"
                name="lastname"
                onChange={onChange}
                placeholder="Last name"
              />
              <input
                type="email"
                name="email"
                onChange={onChange}
                placeholder="Email address"
              />
              <input
                type="date"
                name="dateOfBirth"
                onChange={onChange}
                placeholder="Date of Birth"
              />
              <input
                type="Phone Number"
                name="phoneNumber"
                onChange={onChange}
                placeholder="Phone Number"
              />
              <button
                type="submit"
                className={styles.submit}
                value={spinner}
                disabled={user.loading}
              >
                {user.loading ? (
                  <img src={spinner} alt="" />
                ) : (
                  <span>SIGN UP</span>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
