import React from "react";
import * as d3 from "d3";
class Radial extends React.Component {
  render() {
    const arc = d3
      .arc()
      .innerRadius(this.props.innerRadius)
      .outerRadius(this.props.outerRadius)
      .startAngle(this.props.startAngle)
      .endAngle(this.props.endAngle);

    return (
      <g transform={this.props.transform}>
        <path d={arc()} {...this.props} />
      </g>
    );
  }
}

export default class SupportGuage extends React.Component {
  percentToDeg = perc => {
    return perc * 360;
  };

  percentToRad = perc => {
    return this.degToRad(this.percentToDeg(perc));
  };

  degToRad = deg => {
    return (deg * Math.PI) / 180;
  };

  renderSections = () => {
    const margin = { left: 50, right: 50, top: 100, bottom: 100 };

    const width = Math.round(this.props.width - (margin.left + margin.right));
    const height = Math.round(this.props.height - (margin.top + margin.bottom));

    const radius = Math.min(this.props.width, this.props.height) / 2;
    const sectionPerc = 1 / this.props.sections.length / 2;
    const padRad = 0.05;

    let totalPercent = 0.75;
    const chartInset = 10;
    const barWidth = 20;

    return this.props.sections.map((sectionProps, index) => {
      const arcStartRad = this.percentToRad(totalPercent);
      const arcEndRad = arcStartRad + this.percentToRad(sectionPerc);
      totalPercent += sectionPerc;

      const startPadRad = index === 0 ? 0 : padRad / 2;
      const endPadRad = index === this.props.sections.length ? 0 : padRad / 2;

      const transform = `translate(${this.props.width / 4}, 50)`;

      return (
        <Radial
          key={index + "_radial"}
          transform={transform}
          {...sectionProps}
          width={width}
          height={height}
          innerRadius={radius - chartInset - barWidth}
          outerRadius={radius - chartInset}
          startAngle={arcStartRad + startPadRad}
          endAngle={arcEndRad - endPadRad}
        />
      );
    });
  };

  render() {
    const needleTransform = () => {
      return `translate(${this.props.width / 2}, 70)`;
    };
    const point = this.props.point / this.props.total;
    return (
      <svg width={this.props.width} height={this.props.height}>
        {this.renderSections()}
        <Needle
          percent={point}
          length={35}
          radius={5}
          transform={needleTransform()}
        />
      </svg>
    );
  }
}

class Needle extends React.Component {
  percentToDeg = perc => {
    return perc * 360;
  };

  percentToRad = perc => {
    return this.degToRad(this.percentToDeg(perc));
  };

  degToRad = deg => {
    return (deg * Math.PI) / 180;
  };

  getNeedlePath = () => {
    const thetaRad = this.percentToRad(this.props.percent) / 2;

    const centerX = 0;
    const centerY = 0;

    const topX = centerX - this.props.length * Math.cos(thetaRad);
    const topY = centerY - this.props.length * Math.sin(thetaRad);

    const leftX =
      centerX - this.props.radius * Math.cos(thetaRad - Math.PI / 2);
    const leftY =
      centerY - this.props.radius * Math.sin(thetaRad - Math.PI / 2);

    const rightX =
      centerX - this.props.radius * Math.cos(thetaRad + Math.PI / 2);
    const rightY =
      centerY - this.props.radius * Math.sin(thetaRad + Math.PI / 2);
    return `M ${leftX} ${leftY} L ${topX} ${topY} L ${rightX} ${rightY}`;
  };

  render() {
    const path = this.getNeedlePath();
    return (
      <g transform={this.props.transform}>
        <path className="needle" d={path} fill="#565F62" />
        <circle
          className="needle-center"
          cx="0"
          cy="0"
          r={this.props.radius}
          fill="#565F62"
        />
      </g>
    );
  }
}
