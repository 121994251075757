import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { StoreContext } from "redux-react-hook";

import store from "./store/index";
import setUser from "./utils/setUser";

setUser(store);
ReactDOM.render(
  <StoreContext.Provider value={store}>
    <App />
  </StoreContext.Provider>,
  document.getElementById("root")
);

// serviceWorker.unregister();
