import styles from "./index.module.scss";
import React, { useEffect } from "react";
import check from "../../assets/check.svg";
import close from "../../assets/error.svg";
import { closeNotify } from "../../store/actions/notify.js";
import { useDispatch, useMappedState } from "redux-react-hook";

const DropItem = ({ title, body, type, index }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(closeNotify(index));
    }, 10000);

    return () => {
      clearTimeout(timeout);
    };
  });
  return (
    <div
      className={`${styles.notifyItem} ${
        type === "error" ? styles.error : styles.success
      }`}
    >
      <div className={styles.img}>
        <img src={type === "error" ? close : check} alt="icon" />
      </div>
      <div className={styles.body}>
        <div>
          <span>{title}</span>
          <p>{body}</p>
        </div>
      </div>

      <div
        className={styles.close}
        onClick={() => dispatch(closeNotify(index))}
      />
    </div>
  );
};

const DropDown = () => {
  const messages = useMappedState(state => state.notify.messages);
  return (
    <div className={styles.notify}>
      {messages.map((message, i) => (
        <DropItem
          key={i}
          index={i}
          title={message.title}
          body={message.body}
          type={message.type}
        />
      ))}
    </div>
  );
};

export default DropDown;
