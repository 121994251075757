import {
  AUTH_USER_REQUEST,
  AUTH_USER_ERROR,
  AUTH_USER_SUCCESS,
  LOGOUT_USER,
  UPLOAD_REQUEST,
  UPLOAD_SUCCESS
} from "../actions/types/users";

const INITIAL_STATE = {
  loading: false,
  error: null,
  currentUser: null,
  uploading: false,
  files: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_USER_REQUEST:
      return {
        ...state,
        loading: action.state,
        error: null
      };

    case UPLOAD_REQUEST:
      return {
        ...state,
        uploading: action.state
      };
    case AUTH_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case AUTH_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.user,
        loading: false
      };
    case UPLOAD_SUCCESS:
      return {
        ...state,
        files: action.files
      };
    case LOGOUT_USER:
      return {
        ...state,
        currentUser: null,
        loading: false,
        error: null
      };
    default:
      return state;
  }
};
