import { SET_NOTIFY, CLOSE_NOTIFY } from "./types/notify";

export const setNotify = message => ({
  type: SET_NOTIFY,
  message
});

export const closeNotify = index => ({
  type: CLOSE_NOTIFY,
  index
});
