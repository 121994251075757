import React from "react";
import styles from "./index.module.scss";
import trash from "../../assets/trash.svg";

const Card = ({ name, digits, remove, position }) => {
  return (
    <div
      className={styles.card}
      style={{
        background: `#${position % 2 === 0 ? "720F98" : "0F5798"}`
      }}
    >
      <div className={styles.name}>{name}</div>
      <div className={styles.digitsGroup}>
        <div className={styles.digits}>
          <span>****</span>
          <span>****</span>
          <span>****</span>
          <span> {digits}</span>
        </div>
        <button className={styles.trash} onClick={remove}>
          <img src={trash} alt="trash icon" />
        </button>
      </div>
    </div>
  );
};

export default Card;
