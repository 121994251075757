import { NavLink } from "react-router-dom";
import React from "react";
import homeIconActive from "../../assets/homeIcon.svg";
import homeIcon from "../../assets/homeIcon2.svg";
import vault from "../../assets/vault.svg";
import vaultActive from "../../assets/vault2.svg";
import save from "../../assets/save.svg";
import saveActive from "../../assets/save2.svg";
import user from "../../assets/user.svg";
import userActive from "../../assets/user2.svg";
import news from "../../assets/news.svg";
import newsActive from "../../assets/news2.svg";
import market from "../../assets/market.svg";

import styles from "./index.module.scss";

const Sidebar = ({ url }) => {
  return (
    <div className={styles.sidebar}>
      <NavLink to={`${url}`} exact activeClassName={styles.active}>
        <img className={styles.activeIcon} src={homeIconActive} alt="home" />
        <img className={styles.inactiveIcon} src={homeIcon} alt="home" />
        Home
      </NavLink>
      <NavLink to={`${url}/vault`} activeClassName={styles.active}>
        <img className={styles.activeIcon} src={vaultActive} alt="vault" />
        <img className={styles.inactiveIcon} src={vault} alt="vault" />
        Vault
      </NavLink>
      <NavLink to={`${url}/save-now`} activeClassName={styles.active}>
        <img className={styles.activeIcon} src={saveActive} alt="home" />
        <img className={styles.inactiveIcon} src={save} alt="home" />
        Save Now
      </NavLink>
      <NavLink to={`${url}/account`} activeClassName={styles.active}>
        <img className={styles.activeIcon} src={userActive} alt="account" />
        <img className={styles.inactiveIcon} src={user} alt="account" />
        Account
      </NavLink>
      <NavLink to={`${url}/blockwise`} activeClassName={styles.active}>
        <img src={newsActive} className={styles.activeIcon} alt="blockwise" />
        <img src={news} className={styles.inactiveIcon} alt="blockwise" />
        BlockWise
      </NavLink>
      <NavLink to={`${url}/marketplace`} activeClassName={styles.active}>
        <img src={market} className={styles.activeIcon} alt="market" />
        <img src={market} className={styles.inactiveIcon} alt="market" />
        Marketplace
      </NavLink>
    </div>
  );
};
export default Sidebar;
